import React, { useState } from "react";
import axios from 'axios'

import Layout from "../components/layout";
import SEO from "../components/seo";

function ContactPage() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [status, setStatus] = useState(null);
  const [error, setError] = useState(null);

  const handleSubmit = (evt) => {
    evt.preventDefault();
    setStatus('submit');
    
    const data = JSON.stringify({
      full_name: name,
      email,
      message
    });

    axios({
      method: 'post',
      url: 'https://motrps3pz8.execute-api.us-west-2.amazonaws.com/default/contactFormForward',
      data,
    }).then(() => {
      setStatus('success');
    }).catch(({ response }) => {
      setError(response.data.error);
      setStatus('error');
    });
  };

  return (
    <Layout>
      <SEO
        keywords={[`fashion`, `tech`, `open-source`]}
        title="Contact"
      />
      <section>
        <div className="mx-auto md:w-1/2">
          <h1 className="mb-8 text-4xl">Contact</h1>
          <form className="w-full" onSubmit={handleSubmit}>
            <label
              className="block mb-2 text-xs font-bold uppercase"
              htmlFor="full-name"
            >
              Full Name
            </label>

            <input
              className="w-full mb-6 form-input border"
              id="full-name"
              name="full_name"
              placeholder="Full Name"
              type="text"
              value={name}
              onChange={(evt) => setName(evt.target.value)}
              disabled={status && status !== 'error'}
              required={true}
            />

            <label
              className="block mb-2 text-xs font-bold uppercase"
              htmlFor="email"
            >
              Email
            </label>

            <input
              className="w-full mb-6 form-input border"
              id="email"
              name="email"
              placeholder="Email Address"
              type="text"
              value={email}
              onChange={(evt) => setEmail(evt.target.value)}
              disabled={status && status !== 'error'}
              required={true}
            />

            <label
              className="block mb-2 text-xs font-bold uppercase"
              htmlFor="message"
            >
              Message
            </label>

            <textarea
              className="w-full mb-6 form-textarea border"
              id="message"
              name="message"
              placeholder="What's on your mind?"
              rows="8"
              value={message}
              onChange={(evt) => setMessage(evt.target.value)}
              disabled={status && status !== 'error'}
              required={true}
            />

            {status && (
              <p>
                {status === 'success' && 'Thank you! We will be in touch soon.'}
                {status === 'error'   && (error || 'Sorry, something went wrong. Please try again later.')}
                {status === 'submit'  && <i className="fa fa-spinner fa-spin"/>}
              </p>
            )}

            <input
              type="submit"
              value="Submit"
              className="px-4 py-2 text-sm font-bold text-white bg-gray-700 border-b-4 border-gray-800 rounded hover:border-gray-700 hover:bg-gray-600"
              disabled={status && status !== 'error'}
            />
          </form>
        </div>
      </section>
    </Layout>
  );
}

export default ContactPage;
